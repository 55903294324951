import cn from '@/lib/cn';

interface ThematicPillProps {
    className?: string;
}

const ThematicPill = ({ className }: ThematicPillProps) => (
    <span className={cn('bg-analyst-black rounded-full px-4 py-1.5 text-white font-brand-md', className)}>
        Thematic
    </span>
);

export default ThematicPill;
