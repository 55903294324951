import { MouseEventHandler, TouchEventHandler } from 'react';

import scrollIntoViewWithOffset, { type ScrollIntoViewWithOffsetArgs } from '../scrollIntoViewWithOffset';

const useSmoothScrollTo = ({
    rootElem,
    scrollToElement,
    offsetPosition = 0,
    callback,
}: ScrollIntoViewWithOffsetArgs) => {
    const onClick: MouseEventHandler<HTMLButtonElement> = (evt) => {
        evt.preventDefault();
        if (scrollToElement) {
            scrollIntoViewWithOffset({ callback, offsetPosition, rootElem, scrollToElement });
        }
    };

    const onTouchEnd: TouchEventHandler<HTMLButtonElement> = (evt) => {
        evt.preventDefault();
        if (scrollToElement) {
            scrollIntoViewWithOffset({ callback, offsetPosition, rootElem, scrollToElement });
        }
    };

    return { onClick, onTouchEnd };
};

export default useSmoothScrollTo;
