import { RefObject, useEffect } from 'react';
import { useIntersection } from 'react-use';

import scrollIntoViewWithOffset from '@/helpers/scrollIntoViewWithOffset';

const useIntersectionSnapScroll = (intersectionRef: RefObject<HTMLElement>, options?: IntersectionObserverInit) => {
    const intersection = useIntersection(
        intersectionRef,
        options || {
            root: null,
            rootMargin: '0px',
            threshold: 0.3,
        }
    );

    useEffect(() => {
        if (intersection?.isIntersecting) {
            scrollIntoViewWithOffset({
                scrollToElement: intersectionRef.current,
            });
        }
    }, [intersection, intersectionRef]);
};

export default useIntersectionSnapScroll;
