import { Heading } from '@/components/dom/text-elements';
import SectionWrapper from '@/components/global/SectionWrapper';
import { TryForYourselfLinkButton } from '@/components/global/try-for-yourself-cta';
import YellowBackgroundBurst from '@/components/ui/YellowBackgroundBurst';
import cn from '@/lib/cn';

interface TryItYourselfSectionProps {
    className?: string;
}

const TryItYourselfSection = ({ className }: TryItYourselfSectionProps) => {
    return (
        <SectionWrapper className={cn('pt-20 md:pt-28 mb-10', className)}>
            <div className="bg-white rounded-lg shadow-md p-4 md:p-8 border border-analyst-dark-lavender flex flex-col gap-y-4 md:gap-y-0 xs:flex-row items-center justify-between">
                <div className="flex flex-col gap-3">
                    <Heading
                        importance={3}
                        className="text-2xl md:text-[48px] leading-[1.2] font-alt-riveria-bold"
                    >
                        Still scrolling? <YellowBackgroundBurst>Start building</YellowBackgroundBurst>.
                    </Heading>
                </div>

                <TryForYourselfLinkButton className="flex-shrink-0 lg:text-2xl lg:px-8" />
            </div>
        </SectionWrapper>
    );
};

export default TryItYourselfSection;
