import { Nullable } from '@/types/nullable';

export enum ScrollDirection {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
}

export interface ScrollIntoViewWithOffsetArgs {
    rootElem?: Nullable<HTMLElement | (Window & typeof globalThis)>;
    scrollToElement: Nullable<HTMLElement>;
    offsetPosition?: number;
    callback?: () => void;
    scrollDirection?: ScrollDirection;
    scrollToEnd?: boolean;
}

interface ScrollToArgs {
    rootElem?: Nullable<HTMLElement | (Window & typeof globalThis)>;
    scrollToElement: Nullable<HTMLElement>;
    scrollDirection: ScrollDirection;
    offsetPosition: number;
    scrollToEnd?: boolean;
}

const getScrollToArgs = ({
    rootElem,
    scrollToElement,
    scrollDirection,
    offsetPosition,
    scrollToEnd = false,
}: ScrollToArgs) => {
    if (rootElem && scrollToElement) {
        if (scrollDirection === ScrollDirection.VERTICAL) {
            const elementPosition = scrollToElement?.getBoundingClientRect().top || 0;
            const offsetValue = elementPosition + window.scrollY - offsetPosition;
            const endPos = rootElem instanceof Window ? document.documentElement.scrollHeight : rootElem.scrollHeight;

            return { top: scrollToEnd ? endPos : offsetValue };
        }
        // offsetLeft allows you to get position relative to it's parent
        // container rather than the overall window.
        const elementPosition = scrollToElement?.offsetLeft || 0;
        const offsetValue = elementPosition + window.scrollX - offsetPosition;
        const endPos = rootElem instanceof Window ? document.documentElement.scrollWidth : rootElem.scrollWidth;

        return { left: scrollToEnd ? endPos : offsetValue };
    }
};

export default function scrollIntoViewWithOffset({
    scrollToElement,
    offsetPosition = 0,
    callback,
    rootElem = window,
    scrollDirection = ScrollDirection.VERTICAL,
    scrollToEnd = false,
}: ScrollIntoViewWithOffsetArgs) {
    if (scrollToElement) {
        const scrollToArgs = getScrollToArgs({
            offsetPosition,
            rootElem,
            scrollDirection,
            scrollToElement,
            scrollToEnd,
        });

        rootElem?.scrollTo({
            behavior: 'smooth',
            ...scrollToArgs,
        });

        callback?.();
    }
}
