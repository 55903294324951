import { useCallback, useEffect, useRef, useState } from 'react';

import { IconMap } from '@/components/global/icon';
import IconButton from '@/components/ui/IconButton';
import useWindowResize from '@/helpers/hooks/useWindowResize';
import cn from '@/lib/cn';
import { Nullable } from '@/types/nullable';

export enum TooltipPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
    TOP_CENTER = 'top-center',
    BOTTOM_CENTER = 'bottom-center',
    LEFT_CENTER = 'left-center',
    RIGHT_CENTER = 'right-center',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}

interface TooltipProps {
    className?: string;
    iconClassName?: string;
    tooltipClassName?: string;
    children: React.ReactNode;
    iconType: keyof typeof IconMap;
    tooltipPosition?: TooltipPosition;
    defaultOpen?: boolean;
}

const tooltipPositionMap = {
    [TooltipPosition.TOP]: 'top-0 left-1/2 -translate-x-1/2 -translate-y-full mb-2',
    [TooltipPosition.BOTTOM]: 'bottom-0 left-1/2 -translate-x-1/2 translate-y-full mt-2',
    [TooltipPosition.LEFT]: 'right-full top-1/2 -translate-y-1/2 mr-2',
    [TooltipPosition.RIGHT]: 'left-full top-1/2 -translate-y-1/2 ml-2',
    [TooltipPosition.TOP_CENTER]: 'top-0 left-1/2 -translate-x-1/2 -translate-y-full',
    [TooltipPosition.BOTTOM_CENTER]: 'bottom-0 left-1/2 -translate-x-1/2 translate-y-full',
    [TooltipPosition.LEFT_CENTER]: 'right-full top-1/2 -translate-y-1/2',
    [TooltipPosition.RIGHT_CENTER]: 'left-full top-1/2 -translate-y-1/2',
    [TooltipPosition.TOP_LEFT]: 'top-0 right-full -translate-y-full rounded-br-none',
    [TooltipPosition.TOP_RIGHT]: 'top-0 left-full -translate-y-full rounded-bl-none',
    [TooltipPosition.BOTTOM_LEFT]: 'bottom-0 right-full translate-y-full rounded-tr-none',
    [TooltipPosition.BOTTOM_RIGHT]: 'bottom-0 left-full translate-y-full rounded-tl-none',
};

enum OffscreenPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    LEFT = 'left',
    RIGHT = 'right',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
}

const isOffscreen = (
    el: Nullable<HTMLElement>
): {
    [key in OffscreenPosition]: boolean;
} => {
    if (!el) {
        return {
            [OffscreenPosition.BOTTOM]: false,
            [OffscreenPosition.LEFT]: false,
            [OffscreenPosition.RIGHT]: false,
            [OffscreenPosition.TOP]: false,
            [OffscreenPosition.BOTTOM_LEFT]: false,
            [OffscreenPosition.BOTTOM_RIGHT]: false,
            [OffscreenPosition.TOP_LEFT]: false,
            [OffscreenPosition.TOP_RIGHT]: false,
        };
    }

    const rect = el.getBoundingClientRect();
    const bottomOffscreen = rect.bottom > window.innerHeight;
    const leftOffscreen = rect.left < 0;
    const rightOffscreen = rect.right > window.innerWidth;
    const topOffscreen = rect.top < 0;
    const isTopOrBottomOffscreen = topOffscreen || bottomOffscreen;
    const isLeftOrRightOffscreen = leftOffscreen || rightOffscreen;

    return {
        [OffscreenPosition.BOTTOM]: bottomOffscreen && !isLeftOrRightOffscreen,
        [OffscreenPosition.LEFT]: leftOffscreen && !isTopOrBottomOffscreen,
        [OffscreenPosition.RIGHT]: rightOffscreen && !isTopOrBottomOffscreen,
        [OffscreenPosition.TOP]: topOffscreen && !isLeftOrRightOffscreen,
        [OffscreenPosition.BOTTOM_LEFT]: bottomOffscreen && leftOffscreen,
        [OffscreenPosition.BOTTOM_RIGHT]: bottomOffscreen && rightOffscreen,
        [OffscreenPosition.TOP_LEFT]: topOffscreen && leftOffscreen,
        [OffscreenPosition.TOP_RIGHT]: topOffscreen && rightOffscreen,
    };
};

const tooltipOffscreenRepositionMap: {
    [key in OffscreenPosition]: TooltipPosition;
} = {
    [OffscreenPosition.BOTTOM]: TooltipPosition.TOP,
    [OffscreenPosition.BOTTOM_LEFT]: TooltipPosition.TOP_RIGHT,
    [OffscreenPosition.BOTTOM_RIGHT]: TooltipPosition.TOP_LEFT,
    [OffscreenPosition.LEFT]: TooltipPosition.RIGHT,
    [OffscreenPosition.RIGHT]: TooltipPosition.LEFT,
    [OffscreenPosition.TOP]: TooltipPosition.BOTTOM,
    [OffscreenPosition.TOP_LEFT]: TooltipPosition.BOTTOM_RIGHT,
    [OffscreenPosition.TOP_RIGHT]: TooltipPosition.BOTTOM_LEFT,
};

const TooltipWithIcon = ({
    className,
    iconClassName,
    tooltipClassName,
    children,
    iconType,
    tooltipPosition = TooltipPosition.TOP_RIGHT,
    defaultOpen = false,
}: TooltipProps) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [currentToolTipPosition, setCurrentToolTipPosition] = useState<TooltipPosition>(tooltipPosition);
    const [showTooltip, setShowTooltip] = useState(defaultOpen);
    const toggleTooltip = () => setShowTooltip(!showTooltip);
    const repositionTooltip = useCallback(() => {
        const offscreenPositions: {
            [key in OffscreenPosition]: boolean;
        } = isOffscreen(tooltipRef.current);
        const [offscreenPositionKey] = Object.keys(offscreenPositions).filter(
            (key) => offscreenPositions[key as OffscreenPosition]
        );

        if (offscreenPositionKey) {
            const newPositionKey = tooltipOffscreenRepositionMap[offscreenPositionKey as OffscreenPosition];
            setCurrentToolTipPosition(newPositionKey as TooltipPosition);
        }
    }, []);

    useWindowResize(() => {
        repositionTooltip();
    });

    useEffect(() => {
        repositionTooltip();
    }, [repositionTooltip]);

    return (
        <div className={cn('group hover:cursor-pointer flex items-center gap-2 w-8 h-8 relative', className)}>
            <IconButton
                onTouchEnd={toggleTooltip}
                onClick={toggleTooltip}
                iconType={iconType}
                iconProps={{ className: 'text-white', iconClassName: 'w-full h-full' }}
                className={cn(
                    'w-full h-full p-2 rounded-full bg-primary-bg-linear-gradient text-white hover:opacity-100',
                    iconClassName
                )}
            />

            <div
                ref={tooltipRef}
                className={cn(
                    'min-w-fit z-20 bg-primary-bg-linear-gradient-vertical text-thematic-purple w',
                    'opacity-0 transition-opacity absolute z-10 border border-analyst-dark-lavender shadow-md rounded-full py-1 px-2',
                    'group-focus:opacity-100 group-focus:transition-opacity group-hover:opacity-100 group-hover:transition-opacity',
                    tooltipPositionMap[currentToolTipPosition],
                    showTooltip && 'opacity-100',
                    tooltipClassName
                )}
            >
                {children}
            </div>
        </div>
    );
};

export default TooltipWithIcon;
